







































































































import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import { InputSetups } from "@/mixins/input-setups";
import ToxicFilterHandlerModuleBuilder from '@/includes/logic/Modules/modules/modules/ToxicFilterHandlerModule/ToxicFilterHandlerModuleBuilder';
import SelectOptionsMixin from "@/mixins/SelectOptionsMixin";

import ModuleConfigSettings from '../../../components/ModuleConfigSettings.vue'
import ModuleConfigSettingsWrapper from '../../../components/ModuleConfigSettingsWrapper.vue'
import FilterJournal from "../../../components/filters/FilterJournal.vue";
import FilterCommonSettings from "../../../components/filters/FilterCommonSettings.vue";
import FilterNewUsersSettings from "../../../components/filters/FilterNewUsersSettings.vue";
import FilterRemoveEditedSetting from "../../../components/filters/FilterRemoveEditedSetting.vue";
import FilterExtraSettings from "../../../components/filters/FilterExtraSettings.vue";
import FilterResetPunishSettings from '../../../components/filters/FilterResetPunishSettings.vue'
import FilterSettingLayout from "../../../components/filters/layout/FilterSettingLayout.vue";

import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue';

import { Component, Mixins, VModel } from 'vue-property-decorator'
import { SelectOption } from "piramis-base-components/src/logic/types";

@Component({
  components: {
    FilterSettingLayout,
    FilterExtraSettings,
    FilterRemoveEditedSetting,
    FilterResetPunishSettings,
    FilterNewUsersSettings,
    FilterCommonSettings,
    FilterJournal,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    SelectInput
  },
})
export default class ToxicFilterHandlerModuleView extends Mixins(ModuleBindings, InputSetups, SelectOptionsMixin) {
  @VModel() module!: ToxicFilterHandlerModuleBuilder

  get tags() {
    return [ ... this.module.tariffTags() || [], ...this.module.tags() || [], ]
  }

  mounted() {
    if (this.module.tariffTags()) {
      this.$baseTemplate.saveButton.hide()
    } else {
      this.$baseTemplate.saveButton.show()
    }
  }
}
